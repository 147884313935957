<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="a"
      d="M0,0H24V24H0Z"
    />
    <path
      class="b"
      d="M16,11a3,3,0,1,0-3-3A2.987,2.987,0,0,0,16,11ZM8,11A3,3,0,1,0,5,8,2.987,2.987,0,0,0,8,11Zm0,2c-2.33,0-7,1.17-7,3.5V18a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V16.5C15,14.17,10.33,13,8,13Zm8,0c-.29,0-.62.02-.97.05.02.01.03.03.04.04A4.16,4.16,0,0,1,17,16.5V18a3,3,0,0,1-.18,1H22a1,1,0,0,0,1-1V16.5C23,14.17,18.33,13,16,13Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Group',
};
</script>

<style lang="scss">
.icon {
  .a {
    fill: none;
  }
  .b {
    fill: #1144d7;
  }
}
</style>
